import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

export default ({ data }) => (
	<Layout>
		<SEO title="Relay — Ryan Teuscher" />
		<nav>
			<Link to="/">
				<span className="material-icons">arrow_back</span>
			</Link>
		</nav>
		<header>
			<h1>Relay. Making design inclusive.</h1>
			<h3>
				Acquired by InVision &mdash; Co-Founder & Director of Product Design
			</h3>
			<p>
				Great design requires effective collaboration. With Relay, designers get
				to decide who sees their work and when, while the wider organzation gets
				to provide feedback on their terms <strong>in Slack</strong>. Validating
				design solutions is critical to iterating. Getting to those "Aha"
				moments requires perspective. The kind of perspective gained by
				gathering feedback from the right people at the right time. Designers
				from teams at Google, Square and Airbnb shared their work early and
				often, leading to better products.
			</p>
		</header>

		<section>
			<figure>
				<Image alt="Home on the web" filename="slide-relay-core.png" />
			</figure>
		</section>

		<section>
			<figure>
				<Image alt="Slack" filename="slide-relay-slack.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Chrome app" filename="slide-relay-chrome.png" />
			</figure>
			{/* <article>
				<p>
					Capture and share{" "}
					<strong>
						the full browser or just the viewport, even for localhost.
					</strong>{" "}
					URLs auto-included.
				</p>
			</article> */}
		</section>
		<section>
			<figure>
				<Image alt="Sketch app" filename="slide-relay-sketch.png" />
			</figure>
			{/* <article>
				<p>
					Capture and share artboards{" "}
					<strong>
						with context. Send to your team without leaving the app.
					</strong>
				</p>
			</article> */}
		</section>
		<section>
			<figure>
				<Image alt="Presentations" filename="slide-relay-preso.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Design system" filename="slide-relay-ui.png" />
			</figure>
		</section>
		<section>
			<figure>
				<Image alt="Sharing" filename="slide-relay-share.png" />
			</figure>
		</section>
	</Layout>
)
